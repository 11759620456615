* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'poppins';
}

.font-blue {
  color: #01305D;
}

.point {
  cursor: pointer;
}

/* login-screen-css------------------------------------------------- */

.login-screen {
  background: url("../public/assets/Image-91.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.login-screen::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #0000007c;

}

.login-box {
  width: 450px;
  padding: 20px;
  z-index: 2;
}

.centralize {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box h5 {
  color: #fff;
  margin-bottom: 29px;
  font-weight: 400;
  font-size: 24px;
}

.login-box p {
  color: #fff;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 17px;
}

.login-btn {
  width: 70%;
  background-color: #0B60B0;
  color: #fff;
  font-weight: 600;
  padding: 15px;
  border: 1px solid transparent;
  display: block;
  margin: auto;
  text-decoration: none;
}

.login-btn:hover {
  color: #fff;
}

.logo {
  background-color: linear-gradient(to right, #800080, #5D76CB) ;
  width: 100%;
  overflow: hidden;
  display: block;
  margin: auto;
}

.logo img {
  width: 100%;
}

/* login-credential screen css ------------------------------------- */
.login .login-box h5 {
  color: #000;
  margin-bottom: 29px;
  font-weight: 400;
  font-size: 24px;
}

.login .login-box {
  width: 400px;
  text-align: start;
  padding: 0px 20px;
  z-index: 2;
}

.login-box label {
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

.login-box input {
  padding: 8px 15px 8px;
  font-size: 14px;
  border: 1px solid #90A4AE;
  border-radius: 5px;
  margin-top: 5px;
}

.login-box input::placeholder {
  color: #90A4AE;
  font-size: 14px;
}

.login .login-btn {
  width: 100%;
  background-color: #0B60B0;
  color: #fff;
  font-weight: 600;
  padding: 12px 0px;
  border: 1px solid transparent;
  display: block;
  margin: auto;
}

.login .login-box p {
  color: #000;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  color: #646464;
}

/* otp screen css -------------------------------------------------- */
.otp-btn {
  padding: 12px 25px;
  border-radius: 25px;
  text-align: center;
  color: #27353C;
  background-color: #D3DADD;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  display: block;
  width: 50%;
  margin: auto;
}

.otp p {
  color: #000;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  color: #646464;
  margin-bottom: 10px;
}

.msg-box {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 5rem;
}

.msg-box p i {
  background-color: green;
  border-radius: 50px;
  color: #fff;
  padding: 0px 2px;
  margin-right: 6px;
}

.msg-box p {
  color: #424242;
  font-size: 15px;
  font-weight: 400;
}

/* dashboard css----------------------------------------------------- */
/* top-navbar */
.top-navbar {
  background-color: #072F55;
  padding: 10px 0px;
}

.top-navbar i {
  color: #fff;
  margin: 0px 10px;
}

.dropdown-toggle {
  background-color: #fff;
}

.dash-nav {
  background-color: #0B60B0;
  position: fixed;
  width: 100%;
  top: 0;
}

.dash-nav .nav-link {
  color: #fff !important;
  margin: 0px 25px;
}

.navbar-brand {
  color: #fff;
}

.side-nav {
  width: 15%;
  padding: 18px;
  background: #fff;
  position: fixed;
  z-index: 2;
    margin-top: 5rem;
}

.main-dash {
  width: 85%;
  background-color: #fbfbfb;
  margin-left: 15%;
  padding: 50px 70px;
  min-height: 91vh;
  margin-top: 5rem;
}

.side-nav .nav-link {
  color: #7c7c7c;
  font-size: 13px;
  padding: 0.5rem 1.5rem;
  text-align: start;
  margin-bottom: 1rem;
  font-weight: 500;
}

.side-nav .nav-link i {
  margin-right: 8px;
  font-size: 16px;
}

.side-nav .nav-pills .nav-link.active {
  background-color: #D3E6F8;
  border-radius: 15px;
  color: #000;
}

.create-order {
  background-color: #0B60B0;
  color: #fff;
  font-weight: 600;
  /* padding: 12px 0px; */
  border: 1px solid transparent;
  padding: 6px 25px;
  border-radius: 4px;
}

.dash-table .nav-tabs li {
  width: 25%;
}

.dash-table ul li button {
  width: 100%;
  text-align: start;
  color: #767676;
}

.dash-table {
  margin: 2rem 0rem;
}

.dash-table .dropdown-toggle {
  color: #072F55;
  font-weight: 600;
  font-size: 14px;
}

#myTabContent {
  padding: 2rem 0rem;
  background-color: #fff;
}

.d-table thead {
  background-color: #F0F0F07F;
  font-size: 12px;
  font-weight: 800 !important;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: transparent !important;
}

.table>:not(caption)>*>* {
  padding: 0.8rem 0.5rem !important;
}

.pagination {
  justify-content: end;
}

.me-container {
  padding: 0px 75px;
}

.d-table tbody td {
  font-size: 12px;
  font-weight: 500;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 9;
}

.filter {
  background-color: transparent;
  border: 1px solid #7c7c7c !important;
  color: #7c7c7c !important;
}

.filter::after,
.dots::after {
  display: none !important;
}

.view {
  background-color: #F5F5F5;
  color: #000;
  padding: 3px 0px;
  margin-right: 8px;
  border: 1px solid transparent;
  width: 75px;
}

.reject {
  background-color: #f8eaea;
  color: #D53D3D;
  padding: 3px 10px;
  margin-right: 8px;
  border: 1px solid transparent;
  /* width: 75px; */
  border-radius: 50px;
}

.cursor-text {
  cursor: text !important;
}

.approve {
  background-color: #CCF8E5;
  border: 1px solid transparent;
  padding: 3px 10px;
  border-radius: 50px;
}

.approve i {
  margin-right: 5px;
  color: #00B044;
}

.sbm {
  background-color: #F5F5F5;
  border: 1px solid transparent;
  padding: 3px 10px;
  border-radius: 50px;
  color: #707070;
}

.rjct {
  background-color: #FFDEDE;
  border: 1px solid transparent;
  padding: 3px 10px;
  border-radius: 50px;
  color: #D53D3D;
}

.apr {
  border: 1px solid #E1E1E1;
  color: #212121;
  padding: 3px 0px;
  width: 75px;
  margin-right: 8px;
}

.dots {
  border: 1px solid transparent;
  background: transparent;
  font-weight: 800;
  font-size: 15px;
}

.wordBreake {
  word-break: break-all;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1800px !important;
  }
}

/* new purchase css */
.sbm-purchase {
  background-color: transparent;
  color: #181919;
  font-weight: 600;
  padding: 12px 0px;
  border: 1px solid #181919;
  padding: 8px 25px;
  border-radius: 4px;
  margin-right: 20px;

}

.add-item {
  background-color: transparent;
  color: #0B60B0;
  font-weight: 600;
  padding: 12px 0px;
  border: 1px solid #0B60B0;
  padding: 8px 25px;
  border-radius: 4px;
  margin-right: 20px;
}

.add-vendor {
  background-color: transparent;
  color: #0B60B0;
  border: 1px solid #0B60B0;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 600;
}

.plus-minus-icon {
  background-color: transparent;
  color: #0B60B0;
  font-weight: 600;
  border: 1px solid #0B60B0;
  padding: 2px 10px;
  border-radius: 4px;
}


.add-item i {
  font-weight: 600;
  color: #0B60B0;
}

.back {
  color: #212121;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 2rem;
}

.back i {
  font-weight: 600;
  color: 212121;
}

.vendor-detail {
  border: 1px solid #D8D8D8;
  padding: 20px;
  height: 70vh;
  overflow-y: auto;
}

.form-details label {
  font-weight: 600;
  font-size: 15px;
}

.form-details {
  margin-bottom: 1rem;
}

.form-details input,
.form-details select {
  font-size: 14px;
  /* color: #aaa2a2; */
  border: 1px solid #aaa2a2;
  border-radius: 4px;
}

.vendor-detail h6 {
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 1rem;
}

.border-bottom{
  border-bottom: 1px solid #ccc;
}

.no-border {
  border: none;
}

.next {
  margin: 1rem 0rem;
  padding: 8px 35px;
  background: #000;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #000;
}

.items-detail-box {
  border: 1px solid #D8D8D8;
  padding: 20px;
  height: 70vh;
  overflow-y: auto;
}

.items-detail-box h6 {
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 1rem;
}

.items-detail-box table thead {
  background-color: #F0F0F07F;
  font-size: 12px;
  font-weight: 800 !important;
}

.items-detail-box table tbody td {
  font-size: 12px;
  font-weight: 500;
}

.items-detail-box .form-check-input {
  margin-right: 8px;
  font-size: 15px;
}

.order-review {
  border: 1px solid #D8D8D8;
  padding: 20px;
  overflow-y: auto;
  height: 70vh;
}

.bb {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 1rem;
}

.order-review p {
  font-size: 13px;
}

.order-review .dark {
  font-weight: 600;
}

.order-review .light {
  font-weight: 400;
}

.add-box {
  padding: 15px 0px;
  margin-bottom: 15px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.order-review table thead {
  background-color: #F0F0F07F;
  font-size: 12px;
  font-weight: 800 !important;
}

.order-review table tbody td {
  font-size: 12px;
  font-weight: 500;
}

.amount-box {
  padding: 14px 20px;
  background: #f5f5f5;
  border-radius: 7px;
}

.amount-box p {
  margin-bottom: 8px;
}

.r-of {
  padding: 8px 0px;
  margin-bottom: 8px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.total p {
  color: #212121;
  font-weight: 700;
}

.qt-box {
  width: 55px;
  border: 1px solid #9797977A;
  padding: 3px;
  background: #FAFAFA;
  border-radius: 4px;
}

.un-box {
  width: 55px;
  border: 1px solid #9797977A;
  padding: 3px;
  background: #FAFAFA;
  border-radius: 4px;
}



/* .order-review::-webkit-scrollbar {
  display: none;
} */

.tm-box {
  position: absolute;
  width: 92%;
  bottom: 20px;
  background: #D5DDE568;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: #F5F5F5 0px 1px 0px;
}

.ed-box label {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 5px;
  color: #010101;
  font-weight: 500;
}

.ed-box input,
.ed-box select {
  font-size: 13px;
  color: #010101;
  font-weight: 500;
}

.ed-box input::placeholder {
  font-size: 13px;
  color: #010101;

}

.ed-box {
  margin-bottom: 1rem;
}

.edit-modal {
  padding: 25px !important;
}

.my-fx {
  /* height: 500px;
  overflow-y: scroll; */
}

.my-fx::-webkit-scrollbar {
  display: none;
}

.tm1-box {
  background: #D5DDE568;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: 700;
  box-shadow: #F5F5F5 0px 1px 0px;
  color: #0A0A0A;
}

.remove {
  background-color: transparent;
  color: #FB553F;
  border: 1px solid #FB553F;
  border-radius: 4px;
  font-weight: 600;
  padding: 6px 25px;
  margin-right: 10px;
  font-size: 15px;
}

.save {
  background-color: #181919;
  color: #fff;
  border: 1px solid #181919;
  border-radius: 4px;
  font-weight: 600;
  padding: 6px 25px;
  font-size: 15px;
  cursor: pointer;
}

.btn-close1 {
  width: 30px;
  position: absolute;
  right: -11px;
  top: -11px;
  border-radius: 25px;
  border: 1px solid transparent;
  height: 30px;
  background-color: #01305D;
  color: #fff;
  cursor: pointer;
  z-index: 99;
}



/* extranal css */
.logout a {
  color: rgba(102, 173, 254, 0.719);
}

.logout-icon {
  height: 70px;
  width: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  overflow: hidden;
  margin-bottom: 20px;
  border: 7px solid transparent;
  border-image: linear-gradient(135deg,
      rgba(142, 191, 236, 0.3) 0%,
      rgba(40, 43, 241, 0.719));
  border-image-slice: 1;
}

.btn-custom {
  padding: 0.75rem 3rem;
  width: 100%;
  border-radius: 12px;
  background: rgb(59, 71, 241);
  border: none;
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-weight: bold;
  font-style: var(--font-style-normal);
  font-size: 18px !important;
  line-height: 19px;
  cursor: pointer !important;
}

.btn-custom-ops {
  padding: 0.75rem 2rem;
  width: 100%;
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid rgb(59, 71, 241);
  color: rgb(59, 71, 241) !important;
  font-family: var(--font-montserrat);
  font-weight: bold;
  font-style: var(--font-style-normal);
  font-size: 18px !important;
  line-height: 19px;
  cursor: pointer !important;
}

.block-user {
  text-align: center;
}

.block-user h1 {
  font-family: var(--font-montserrat);
  font-weight: bold;
  font-style: var(--font-style-normal);
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}


.react-tel-input .special-label {
  display: none;
}

.signatureCanvasParent {
  text-align: center;
  background-color: white;
}

.signatureCanvas {
  border: 1px solid black;
  width: 50vw;
  min-height: 50vh;
  background-color: white;
}

.price-card {
  background: #D5DDE568 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 0px #F5F5F5;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: -webkit-fill-available;
}

.rounded-image {
  height: 70px;
  width: 70px;
  border-radius: 70px;
}



.uploadFile {
  position: relative;
}

.uploadFile svg {
  rotate: 45deg;
}

.uploadFile [type="file"] {
  cursor: pointer !important;
  display: block;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 0px;
  z-index: 1;
}

.filename {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-style: var(--font-style-normal);
  font-size: 14px;
  line-height: 18px;
}

.btn_upload {
  padding: 0.15rem 2rem;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
  background-color: var(--main-color);
  color: var(--color-black);
  border-radius: 0.5rem;
  max-height: 100px;
  height: 100%;
  cursor: pointer;
}

.btn_upload input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}

.file_uploading {
  width: 100%;
  height: 10px;
  margin-top: 20px;
  background: #ccc;
}

.file_uploading .btn_upload {
  display: none;
}

.req-error {
  color: red;
  text-align: start;
}


.btn_upload_file {
  opacity: 0;
  cursor: pointer;
  /* width: 0px;
   height: 0px; */
   
  font-size: 0;
  position: absolute;
}

.css-qbdosj-Input {
 cursor: pointer;
}

.css-1xc3v61-indicatorContainer {
  cursor: pointer;
}

.dropdown-item{
  cursor: pointer;
}

.form-select {
  cursor: pointer;
}


.form-select[disabled] {
  cursor: default;
}

th {
  text-transform: uppercase;
}

.cursor-default{
  cursor: default;
}

.suggestion-list {
  /* position: absolute; */
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  margin: 0;
  padding: 0;

  /* max-height: 200px; 
  overflow-y: auto;  */
  left: 15px;
  right: 15px;
  width: auto;
}

.suggestion-list-item {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  cursor: pointer;
  margin-bottom: 0;
  font-family: "poppins";
}

.modal-content{
  border-radius: 1rem;
}

.tab-icon{
  width: 16px;
}


/*  for autofill fields highlight issue */
/* Chrome / Webkit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
}

/* Firefox */
input {
  filter: none;
}
/* report-dashboard-css */
.dash-nav-report {
  position: fixed;
  background-color: #fbfbfb;
  width: 77%;
  top: 75px;
  padding-top: 3rem;
  height: 110px;
}

.line-border-bottom{
  line-height: 0.72cm;
  text-align: left;
  margin-bottom: 0.49cm;
  background: transparent;
  border-top: none;
  border-bottom: 1px solid #cccccc;
  border-left: none;
  border-right: none;
  padding-top: 0cm;
  padding-bottom: 0.42cm;
  padding-left: 0cm;
  padding-right: 0cm;
}

.save-btn{
  background-color: transparent;
  color: #22b374;
  border: 1px solid #22b374;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 600;
}
.unsave-btn{
  background-color: transparent;
  color: #D53D3D;
  border: 1px solid #D53D3D;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 600;
}